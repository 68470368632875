var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.configSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "全局参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      [
                        _c("el-form-item", {
                          staticClass: "compact-form-item",
                          attrs: {
                            label: "若您希望新增单据时商品数量默认填充，请勾选",
                          },
                        }),
                        _c(
                          "el-radio",
                          {
                            staticClass: "marT10",
                            attrs: { label: "0" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11",
                            },
                          },
                          [_vm._v("单据新增数量默认为0")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11",
                            },
                          },
                          [_vm._v("单据新增数量默认为1")]
                        ),
                        _c("el-form-item", [
                          _c("div", { staticClass: "marT15" }, [
                            _c("span", [
                              _vm._v(
                                "若您希望业务单据对账后，不能再反审核修改，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": "1",
                                      "false-label": "",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_12,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_12",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                    form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_12\n                  ",
                                    },
                                  },
                                  [_vm._v("不允许反审核已对账单据")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", [
                          _c("div", [
                            _c("span", [
                              _vm._v("进销存账本金额计算规则，请勾选"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_AMOUNT_CALCULATE_RULE_61,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_AMOUNT_CALCULATE_RULE_61",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                    form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_AMOUNT_CALCULATE_RULE_61\n                  ",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("成本价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("标准售价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("标准进价"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", [
                          _c("div", [
                            _c("span", [_vm._v("零出库成本规则，请勾选")]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PROCUREMENT_ZERO_STOCK_COST_PRICE_CHOOSE_METHOED_62,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PROCUREMENT_ZERO_STOCK_COST_PRICE_CHOOSE_METHOED_62",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                    form.system_BUSINESS_PROCUREMENT_ZERO_STOCK_COST_PRICE_CHOOSE_METHOED_62\n                  ",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("本仓库上次出库成本"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("标准进价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("本仓库上次入库成本"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "4" } }, [
                                      _vm._v("最新进价"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("el-form-item", [
                          _c("div", [
                            _c("span", [
                              _vm._v("红字出库无成本价时成本规则，请勾选"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PROCUREMENT_MINUS_STOCK_COST_PRICE_CHOOSE_METHOED_63,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PROCUREMENT_MINUS_STOCK_COST_PRICE_CHOOSE_METHOED_63",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                    form.system_BUSINESS_PROCUREMENT_MINUS_STOCK_COST_PRICE_CHOOSE_METHOED_63\n                  ",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("本仓库上次出库成本"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("标准进价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("本仓库上次入库成本"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "4" } }, [
                                      _vm._v("最新进价"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "5" } }, [
                                      _vm._v("结存单价"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "采购参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      [
                        _c("el-form-item", {
                          staticClass: "compact-form-item",
                          attrs: {
                            label:
                              "若您在新增采购订单、采购入库单、采购退货单时采购单价为0，您希望",
                          },
                        }),
                        _c(
                          "el-radio",
                          {
                            staticClass: "marT10",
                            attrs: { label: "1" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21",
                            },
                          },
                          [_vm._v("不控制")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21",
                            },
                          },
                          [_vm._v("提示，允许继续操作")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "3" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21",
                            },
                          },
                          [_vm._v("提示，不允许继续操作")]
                        ),
                        _c("el-form-item", [
                          _c("div", { staticClass: "marT20" }, [
                            _c("span", [
                              _vm._v(
                                "若您允许采购入库单的入库数量大于订单数量，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": "1",
                                      "false-label": "",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_22,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_22",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                    form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_22\n                  ",
                                    },
                                  },
                                  [_vm._v(" 允许入库数量大于订单数量 ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("采购收货单，必须按采购订单收货，"),
                        ]),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23",
                            },
                          },
                          [_vm._v(" 数量不允许修改 ")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23",
                            },
                          },
                          [_vm._v(" 价格不允许修改 ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "marT15" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { "false-label": "", "true-label": "1" },
                                model: {
                                  value:
                                    _vm.form
                                      .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_24,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_24",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_24",
                                },
                              },
                              [_vm._v("采购订单只能被引用一次 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marT15" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { "false-label": "", "true-label": "1" },
                                model: {
                                  value:
                                    _vm.form
                                      .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_25,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_25",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_25",
                                },
                              },
                              [
                                _vm._v(
                                  " 采购收货单审核后,自动更新商品资料进价 "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marT15" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { "false-label": "", "true-label": "1" },
                                model: {
                                  value:
                                    _vm.form
                                      .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_26,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_26",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_26",
                                },
                              },
                              [
                                _vm._v(
                                  " 采购收货单审核后,自动更新商品资料主供应商 "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marT15" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { "false-label": "", "true-label": "1" },
                                model: {
                                  value:
                                    _vm.form
                                      .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_27,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_27",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_27",
                                },
                              },
                              [_vm._v("库存量高于最高库存允许采购 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "盘点参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c("el-form", [
                      _c(
                        "div",
                        [
                          _vm._v(" 盘点显示账面数 "),
                          _c("el-switch", {
                            staticClass: "marL20",
                            attrs: {
                              "active-value": "Y",
                              "inactive-value": "N",
                            },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_INVENTORY_31,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_INVENTORY_31",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_INVENTORY_31",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "销售参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      [
                        _c("div", { staticClass: "marT15 marB10" }, [
                          _vm._v(
                            " 若您在新增销售订单、销售出库单、销售退货单时销售单价为0,您希望 "
                          ),
                        ]),
                        _c(
                          "el-radio",
                          {
                            staticClass: "marT10",
                            attrs: { label: "1" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41",
                            },
                          },
                          [_vm._v("不控制")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41",
                            },
                          },
                          [_vm._v("提示，允许继续操作")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "3" },
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41",
                            },
                          },
                          [_vm._v("提示，不允许继续操作")]
                        ),
                        _c("el-form-item", [
                          _c("div", { staticClass: "marT20" }, [
                            _c("div", [
                              _vm._v(
                                "若您允许销售出库单的出库数量大于订单数量，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-switch",
                                  {
                                    attrs: {
                                      "active-value": "1",
                                      "inactive-value": "",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_42,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_42",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_42",
                                    },
                                  },
                                  [_vm._v(" 允许出库数量大于订单数量 ")]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v("允许出库数量大于订单数量")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "el-form-item",
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": "Y",
                                "inactive-value": "N",
                              },
                              model: {
                                value:
                                  _vm.form
                                    .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_43,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_43",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_43",
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v("销售订单启用生产班次")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "可用库存参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c("div", [
                    _vm._v(
                      "若您需要调整可用库存请设置预计入库参数和预计出库参数"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "big x-f" }, [
                  _c("div", { staticStyle: { "border-top": "white" } }, [
                    _c("div", { staticClass: "center x-f" }, [
                      _c("div", { staticClass: "middleL x-c" }, [
                        _vm._v("可用库存 = 当前库存量 +"),
                      ]),
                      _c("div", { staticClass: "middleR x-c" }, [
                        _vm._v("预计入库量"),
                      ]),
                    ]),
                    _c("div", { staticClass: "bottom x-bc" }, [
                      _c(
                        "div",
                        { staticClass: "padd15" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value:
                                  _vm.form
                                    .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "padd15",
                                  attrs: { label: "1" },
                                },
                                [_vm._v("已审核采购订单数")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "padd15",
                                  attrs: { label: "2" },
                                },
                                [_vm._v("未审核产品入库单")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "padd15",
                                  attrs: { label: "3" },
                                },
                                [_vm._v("未审核采购入库单")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "padd15",
                                  attrs: { label: "4" },
                                },
                                [_vm._v("已审核生产计划单")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "padd15",
                                  attrs: { label: "5" },
                                },
                                [_vm._v("已审核调拨出库单(针对收货方)")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("span", [_c("i", { staticClass: "el-icon-minus" })]),
                  _c("div", { staticStyle: { "border-top": "white" } }, [
                    _c("div", { staticClass: "x-f" }, [
                      _c("div", { staticClass: "centerL x-c" }, [
                        _vm._v("预计出库量"),
                      ]),
                      _c("div", { staticClass: "centerR x-c" }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "bottom x-bc padd15" },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticClass: "padd15",
                            model: {
                              value:
                                _vm.form
                                  .system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52",
                                  $$v
                                )
                              },
                              expression:
                                "form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              { staticClass: "padd15", attrs: { label: "1" } },
                              [_vm._v("已审核销售订单")]
                            ),
                            _c(
                              "el-checkbox",
                              { staticClass: "padd15", attrs: { label: "2" } },
                              [_vm._v("未审核调拨出库单")]
                            ),
                            _c(
                              "el-checkbox",
                              { staticClass: "padd15", attrs: { label: "3" } },
                              [_vm._v("未审核销售出库单")]
                            ),
                            _c(
                              "el-checkbox",
                              { staticClass: "padd15", attrs: { label: "4" } },
                              [_vm._v("未审核生产领料单")]
                            ),
                            _c(
                              "el-checkbox",
                              { staticClass: "padd15", attrs: { label: "5" } },
                              [_vm._v("零售占用量(线上订单未取货)")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }